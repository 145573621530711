import clsx from "clsx";
import { Fodmap } from "../../contracts";
import { Alert } from "../Alert";
import classes from "./FodmapsList.module.scss";

interface FodmapsListProps {
  /**
   * List of FODMAPs to display.
   */
  fodmaps: Fodmap[];
}

export const FodmapsList = ({ fodmaps }: FodmapsListProps) => {
  const variantMap = {
    low: "success",
    medium: "warning",
    high: "danger",
  };

  return (
    <ul className={clsx(classes["fodmaps-list"], "list-unstyled mb-0")}>
      {fodmaps.map(({ label, level }) => {
        const variant = level
          ? (variantMap[level] as "success" | "warning" | "danger")
          : undefined;

        return (
          <li key={label}>
            {variant ? <Alert variant={variant}>{label}</Alert> : label}
          </li>
        );
      })}
    </ul>
  );
};
