/**
 * Hard-coded list of Food categories.
 *
 * If Food needs more than one type of category, or these items change regularly,
 * consider moving this to Firestore.
 */
export const FoodCategory = {
  Fruits: "fruits",
  Drinks: "drinks",
  Grains: "grains",
  Soups: "soups",
  Entrées: "entrees",
  Gravy: "gravy",
  "Stir Fry": "stir-fry",
  Dairy: "dairy",
  "Nuts and Seeds": "nuts-seeds",
  Oils: "oils",
  "Chips, Puffs, Crackers": "chips-puffs-crackers",
  Miscellaneous: "miscellaneous",
} as const;

export type FoodCategoryEnum = (typeof FoodCategory)[keyof typeof FoodCategory];
