import {
  User as FirebaseUser,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import {
  DocumentData,
  DocumentReference,
  doc,
  getDoc,
} from "firebase/firestore";

import { User, UserType } from "../contracts";
import { auth, db } from "../firebase";

interface UserMetadata {
  /**
   * Reference to the user's channel collection.
   */
  channel: DocumentReference;

  /**
   * Type of user.
   */
  type: UserType;
}

export const login = async (email: string, password: string) => {
  return signInWithEmailAndPassword(auth, email, password);
};

export const logout = async () => {
  return signOut(auth);
};

export const getUser = async (user: FirebaseUser) => {
  const userMetadataResult = await getUserMetadata(user.uid);

  if (!userMetadataResult.exists()) {
    throw new Error(`Unable to retrieve user metadata for ${user.uid}`);
  }

  const userMetadata = transformUserMetadata(userMetadataResult.data());
  const result = transformUser(user, userMetadata);

  return result;
};

export const getUserMetadata = async (userId: string) => {
  const userDoc = doc(db, "users", userId);
  return getDoc(userDoc);
};

const transformUser = (
  user: FirebaseUser,
  userMetadata: UserMetadata
): User => {
  return {
    channelId: userMetadata.channel.id,
    id: user.uid,
    name: user.displayName ?? "",
    type: userMetadata.type,
  };
};

const transformUserMetadata = (data: DocumentData): UserMetadata => {
  return {
    channel: data.channel,
    type: data.type,
  };
};
