import { isAfter, subWeeks } from "date-fns";
import { Badge } from "../Badge";
import { TimeAgo } from "../TimeAgo";

interface MaybeNewRecipeBadgeProps {
  /**
   * When the Food recipe was last updated.
   */
  recipeUpdatedAt: Date;

  /**
   * Show the time the recipe was updated.
   *
   * @default false
   */
  showTime?: boolean;
}

/**
 * Optionally display a "new" badge if the recipe was updated within the last couple
 * weeks.
 */
export function MaybeNewRecipeBadge({
  recipeUpdatedAt,
  showTime = false,
}: MaybeNewRecipeBadgeProps) {
  const twoWeeksAgo = subWeeks(new Date(), 2);
  const showDate = isAfter(recipeUpdatedAt, twoWeeksAgo);

  if (!showDate) {
    return null;
  }

  return (
    <Badge variant="primary">
      New {showTime ? <TimeAgo date={recipeUpdatedAt} capitalize /> : null}
    </Badge>
  );
}
