import { useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useDebounce } from "react-use";
import classes from "./EditorListSearch.module.scss";

export interface EditorListSearchProps {
  /**
   * The current search query is controlled.
   */
  query: string;

  /**
   * Callback for emitting a new search query.
   * @param query New search query.
   */
  onSearch: (query: string) => void;
}

/**
 * Editor app search bar for filtering foods.
 */
export const EditorListSearch = ({
  query,
  onSearch,
}: EditorListSearchProps) => {
  const [inputQuery, setInputQuery] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  // Propagate the new query after the debounce period, to avoid too much processing.
  useDebounce(
    () => {
      onSearch(inputQuery);
    },
    350,
    [inputQuery]
  );

  // Sync internal query with the external query. They are separate to support debouncing.
  useEffect(() => {
    setInputQuery(query);
  }, [query]);

  // Add event listener for 'Esc' key to clear the search
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setInputQuery("");
      }
    };

    const inputElement = inputRef.current;
    if (inputElement) {
      inputElement.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, [onSearch]);

  return (
    <Form.Group className={classes["editor-list-search"]}>
      <Form.Control
        id="query"
        type="text"
        placeholder="Search"
        value={inputQuery}
        ref={inputRef}
        onChange={(event) => setInputQuery(event.target.value)}
      />
      {query ? (
        <Button variant="outline-secondary" onClick={() => onSearch("")}>
          Clear
        </Button>
      ) : null}
    </Form.Group>
  );
};
