import clsx from "clsx";
import classes from "./Badge.module.css";

interface BadgeProps {
  children: React.ReactNode;

  /**
   * Additional class name.
   */
  className?: string;

  /**
   * Badge color variant.
   *
   * @default "default"
   */
  variant?: "default" | "primary";
}

export function Badge({
  children,
  className,
  variant = "default",
}: BadgeProps) {
  return (
    <div
      className={clsx(
        classes.badge,
        variant === "primary" && classes["badge--primary"],
        className
      )}
    >
      {children}
    </div>
  );
}
