import { BowlSteam, CalendarDot } from "@phosphor-icons/react";
import clsx from "clsx";
import { Nav, Navbar } from "react-bootstrap";
import { Link } from "wouter";
import classes from "./ViewerNav.module.css";

export const ViewerNav = () => {
  return (
    <Navbar fixed="bottom" className={classes["viewer-nav"]}>
      <Nav variant="pills" defaultActiveKey="/" className={classes.nav}>
        <Nav.Item>
          <Link
            href="/"
            className={(active) =>
              clsx(classes["nav-link"], active && classes.active)
            }
          >
            <CalendarDot size={32} />
            Today
          </Link>
        </Nav.Item>
        <Nav.Item>
          <Link
            href="/all"
            className={(active) =>
              clsx(classes["nav-link"], active && classes.active)
            }
          >
            <BowlSteam size={32} />
            All
          </Link>
        </Nav.Item>
      </Nav>
    </Navbar>
  );
};
