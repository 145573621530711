import { FoodCategoryEnum } from "./food-category";

export interface Food {
  /**
   * Firestore unique ID.
   */
  id: string;

  /**
   * Title of the Food.
   *
   * @example 'Potato Soup'
   */
  title: string;

  /**
   * Optional subtitle of the Food.
   *
   * @example 'sweet, fresh, with pits'
   * @example 'Organic Valley'
   */
  subtitle?: string;

  /**
   * Description of the serving size.
   *
   * @example '1 mug'
   */
  servingSize: string;

  /**
   * Calories in the serving size.
   *
   * @example 16
   */
  calories: number;

  /**
   * Macros in the serving size e.g. carbs, protein, fat.
   */
  macros: Macros;

  /**
   * Nutrients in the serving size e.g. calcium, magnesium.
   */
  nutrients: Nutrients;

  /**
   * Fiber in the serving size e.g. total, insoluble, soluble.
   */
  fiber: Fiber;

  /**
   * FODMAP levels for the Food.
   */
  fodmaps: Fodmap[];

  /**
   * Category of the Food.
   *
   * @example 'fruits'
   */
  category: FoodCategoryEnum;

  /**
   * Is the Food archived?
   */
  archived: boolean;

  /**
   * Is the Food on today's menu?
   */
  onMenu: boolean;

  /**
   * URL to the Food's photo stored in Firebase Storage.
   */
  photoUrl?: string;

  /**
   * List of ingredients in the Food.
   */
  ingredients?: string[];

  /**
   * When the Food was created.
   *
   * @example '2024-01-01T00:00:00.000Z'
   */
  createdAt: Date;

  /**
   * When the Food was last updated.
   *
   * @example '2024-06-01T00:00:00.000Z'
   */
  updatedAt?: Date;

  /**
   * When the Food's recipe changed.
   *
   * @example '2021-04-01T00:00:00.000Z'
   */
  recipeUpdatedAt?: Date;

  /**
   * When the Food was archived.
   *
   * @example '2021-07-01T00:00:00.000Z'
   */
  archivedAt?: Date;
}

interface Macros {
  /**
   * Total carbohydrates in grams.
   */
  carbohydrates: number;

  /**
   * Total protein in grams.
   */
  protein: number;

  /**
   * Total fat in grams.
   */
  fat: number;
}

interface Nutrients {
  /**
   * Total calcium in milligrams.
   */
  calcium?: number;

  /**
   * Total magnesium in milligrams.
   */
  magnesium?: number;
}

interface Fiber {
  /**
   * Total fiber in grams.
   */
  total: number;

  /**
   * Insoluble fiber in grams.
   */
  insoluble: number;

  /**
   * Soluble fiber in grams.
   */
  soluble: number;
}

/**
 * FODMAP level information.
 *
 * Indicates the amount of Food in the low, medium, or high safety threshold.
 */
export const FodmapLevel = {
  Low: "low",
  Medium: "medium",
  High: "high",
} as const;

export type FodmapLevelEnum = (typeof FodmapLevel)[keyof typeof FodmapLevel];

export interface Fodmap {
  /**
   * FODMAP information.
   *
   * The source data has a wide variety of descriptions, so we're using a string
   * to represent the data.
   *
   * @example 'Low – 1 mug (up to 6 mugs)'
   * @example '(fructan)'
   */
  label: string;

  /**
   * Optional FODMAP level to indicate safety.
   */
  level?: FodmapLevelEnum;

  /**
   * Show in the main list, vs only in the detailed view.
   */
  showInMainView: boolean;
}
