import { Button, Modal } from "react-bootstrap";

import { useAppContext } from "../../hooks";
import { config } from "../../config";

/**
 * Modal with user's settings such as account, security, etc.
 */
export const SettingsModal = () => {
  const { editorMode, showSettings, logout, updateAppState } = useAppContext();

  const handleClose = () => {
    updateAppState({ showSettings: false });
  };

  const handleLogout = () => {
    let warning = "Are you sure you want to log out?";

    if (editorMode) {
      warning = `${warning}\n\nWARNING: If you have not stored your key, you will no longer be able to access the your data!`;
    }

    if (window.confirm(warning)) {
      logout();
    }
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <Modal onHide={handleClose} show={showSettings}>
      <Modal.Header closeButton>
        <Modal.Title>Food Friend - Settings</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>Account</h5>
        <p className="mb-0">
          <Button variant="outline-secondary" onClick={handleLogout}>
            Log out
          </Button>
        </p>

        <hr />

        <h5>Version</h5>
        <p>{config.appVersion}</p>
        <p className="mb-0">
          <Button variant="outline-secondary" onClick={handleRefresh}>
            Reload app
          </Button>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose} variant="secondary">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
