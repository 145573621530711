import { Plus, Trash } from "@phosphor-icons/react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Fodmap, FodmapLevel } from "../../contracts";

interface FodmapsFieldsProps {
  /**
   * Controlled list of FODMAPs.
   */
  fodmaps: Fodmap[];

  /**
   * Callback for emitting a new list of FODMAPs.
   *
   * @param fodmaps New list of FODMAPs.
   */
  onChange(fodmaps: Fodmap[]): void;
}

/**
 * Form fields for managing multiple FODMAP values.
 */
export const FodmapsFields = ({ fodmaps, onChange }: FodmapsFieldsProps) => {
  function addFodmap() {
    const newFodmaps = [...fodmaps, { label: "", showInMainView: false }];
    onChange(newFodmaps);
  }

  function deleteFodmap(index: number) {
    const newFodmaps = fodmaps.filter((_, i) => i !== index);
    onChange(newFodmaps);
  }

  function handleFieldChange(
    index: number,
    key: "label" | "level" | "showInMainView",
    value: string | boolean | undefined
  ) {
    const newFodmaps = fodmaps.map((fodmap, i) => {
      if (i === index) {
        return {
          ...fodmap,
          [key]: value,
        };
      }
      return fodmap;
    });
    onChange(newFodmaps);
  }

  const fodmapRows = fodmaps.map(({ label, level, showInMainView }, index) => {
    return (
      <Row key={index} className="align-items-center mb-3">
        <Col md={4}>
          <Form.Control
            onChange={(event) =>
              handleFieldChange(index, "label", event.target.value)
            }
            required
            type="text"
            value={label}
          />
        </Col>
        <Col md={4}>
          <Form.Select
            value={level}
            onChange={(event) =>
              handleFieldChange(index, "level", event.target.value)
            }
          >
            <option value="">Select a FODMAP level</option>
            {Object.entries(FodmapLevel).map(([label, value]) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Form.Select>
        </Col>
        <Col md={2}>
          <Form.Check
            type="switch"
            checked={showInMainView}
            onChange={(event) =>
              handleFieldChange(index, "showInMainView", event.target.checked)
            }
          />
        </Col>
        <Col md={2} className="d-flex align-items-center justify-content-end">
          <Button
            type="button"
            variant="danger"
            onClick={() => deleteFodmap(index)}
          >
            <Trash size={24} />
          </Button>
        </Col>
      </Row>
    );
  });

  return (
    <>
      <Row>
        <Col md={4}>
          <Form.Label>Label*</Form.Label>
        </Col>
        <Col md={4}>
          <Form.Label>FODMAP Level</Form.Label>
        </Col>
        <Col md={2}>
          <Form.Label>Show In List</Form.Label>
        </Col>
      </Row>

      <div>
        {fodmapRows}
        <div className="d-flex justify-content-end">
          <Button
            type="button"
            variant="light"
            className="d-flex align-items-center gap-1"
            onClick={() => addFodmap()}
          >
            <Plus />
            Add FODMAP
          </Button>
        </div>
      </div>
    </>
  );
};
