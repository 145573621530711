import { useFoods, useSearchFoods } from "../../hooks";
import { Food } from "../FoodsList/Food";
import { useEffect, useState } from "react";
import { Food as FoodType } from "../../contracts";

export interface ArchivedLinkListProps {
  /**
   * The search query to filter the links by.
   */
  query: string;
}

/**
 * Display the archived links, ordered chronologically.
 */
export const ArchivedLinkList = ({ query }: ArchivedLinkListProps) => {
  const { archivedFoods: foods } = useFoods();
  const { search } = useSearchFoods(foods);
  const [foodsList, setFoodsList] = useState<FoodType[]>([]);

  useEffect(() => {
    let newFoodsList = [...foods];

    if (query) {
      const searchedFoods = search(query);
      newFoodsList = newFoodsList.filter((food) =>
        searchedFoods.some((searchedFood) => searchedFood.id === food.id)
      );
    }

    setFoodsList(newFoodsList);
  }, [query, foods]);

  return (
    <div className={"list-group"}>
      {foodsList.map(({ id }) => (
        <Food id={id} key={id} />
      ))}
    </div>
  );
};
