import { useState } from "react";
import { Button, Modal } from "react-bootstrap";

import { FoodModalContext } from "../../contexts";
import { useAppContext } from "../../hooks";
import { FoodForm } from "./FoodForm";

export const FoodModal = () => {
  const { foodManagementState, updateAppState } = useAppContext();
  const showingFoodForm = !!foodManagementState;
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [formDirty, setFormDirty] = useState(false);

  /**
   * Stable labels when opening/closing modal.
   * If we referenced foodManagementState.id directly, as the modal is closing the
   * labels would change because unsetting foodManagementState.id is what closes
   * the modal.
   */
  const [actionLabel, setActionLabel] = useState(
    foodManagementState?.id ? "Update" : "Add"
  );

  const handleClose = () => {
    if (
      formDirty &&
      !window.confirm("Are you sure you want to discard your changes?")
    ) {
      return;
    }

    close();
    setFormDirty(false);
  };

  const close = () => {
    updateAppState({ foodManagementState: undefined });
  };

  return (
    <FoodModalContext.Provider
      value={{
        dirty: formDirty,
        setDirty: setFormDirty,
        setSubmitting: setFormSubmitting,
        submitting: formSubmitting,
      }}
    >
      <Modal
        size="lg"
        onEnter={() => {
          setActionLabel(foodManagementState?.id ? "Update" : "Add");
        }}
        onHide={handleClose}
        show={showingFoodForm}
      >
        <Modal.Header closeButton>
          <Modal.Title>{actionLabel} Food</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FoodForm />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} variant="secondary">
            Cancel
          </Button>
          <Button
            disabled={formSubmitting}
            form="food-form"
            type="submit"
            variant="primary"
          >
            {formSubmitting ? (
              <span
                aria-hidden="true"
                className="spinner-border spinner-border-sm me-2"
                role="status"
              ></span>
            ) : null}
            {actionLabel}
          </Button>
        </Modal.Footer>
      </Modal>
    </FoodModalContext.Provider>
  );
};
