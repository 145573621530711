import {
  DocumentData,
  addDoc,
  collection,
  deleteDoc,
  deleteField,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";

import { Food } from "../contracts";
import { db } from "../firebase";

/**
 * Add a new Food to the Food collection under a channel.
 *
 * @param food New Food without an ID.
 * @param channelId Channel to add the Food to.
 */
export const addFood = async (
  food: Omit<Food, "id" | "createdAt" | "updatedAt" | "archivedAt">,
  channelId: string
) => {
  const foodsCollectionRef = buildFoodsCollectionRef(channelId);
  const transformedFood = await transformFromFood(food);

  return addDoc(foodsCollectionRef, {
    ...transformedFood,
    createdAt: serverTimestamp(),
  });
};

export const updateFood = async (
  { id, ...food }: Partial<Food>,
  channelId: string
) => {
  const foodsCollectionRef = buildFoodsCollectionRef(channelId);
  const foodDoc = doc(foodsCollectionRef, id);
  const transformedFood = await transformFromFood(food);

  return updateDoc(foodDoc, {
    ...transformedFood,
    recipeUpdatedAt: transformedFood.recipeUpdatedAt ?? deleteField(),
    updatedAt: serverTimestamp(),
  });
};

export const subscribeFoods = (
  /**
   * Run this callback when the subscription changes.
   */
  callback: (food: Food[]) => void,

  /**
   * Channel to subscribe to. The user must have permission to read from this channel.
   */
  channelId: string,

  /**
   * Include archived Foods in the query (for the Editor).
   */
  includeArchived: boolean
) => {
  const foodsCollectionRef = buildFoodsCollectionRef(channelId);
  let q = query(
    foodsCollectionRef,
    /* "date" generally doesn't have time information (start of day) because of
    the form date selector input. To ensure newly Foods that have the same date
    as other newly created Foods appear first, we also sort on the createdAt timestamp. */
    orderBy("createdAt", "desc")
  );

  if (!includeArchived) {
    // Requires all Foods to have an `archived` field, even if it's false.
    q = query(q, where("archived", "!=", true));
  }

  const unsubscribe = onSnapshot(
    q,
    async (querySnapshot) => {
      const results = await Promise.all(
        querySnapshot.docs.map((doc) => transformToFood(doc.id, doc.data()))
      );

      callback(results);
    },
    (error) => {
      throw error;
    }
  );

  return unsubscribe;
};

export const getFoods = async (channelId: string): Promise<Food[]> => {
  const foodsCollectionRef = buildFoodsCollectionRef(channelId);
  const querySnapshot = await getDocs(foodsCollectionRef);

  const results = await Promise.all(
    querySnapshot.docs.map((doc) => transformToFood(doc.id, doc.data()))
  );

  return results;
};

export const getFood = async (foodId: string, channelId: string) => {
  const foodsCollectionRef = buildFoodsCollectionRef(channelId);
  const docRef = doc(foodsCollectionRef, foodId);
  const foodDoc = await getDoc(docRef);
  const data = foodDoc.data();

  if (!data) {
    return undefined;
  }

  return await transformToFood(foodId, data);
};

export const deleteFood = (foodId: string, channelId: string) => {
  const foodsCollectionRef = buildFoodsCollectionRef(channelId);
  const docRef = doc(foodsCollectionRef, foodId);

  return deleteDoc(docRef);
};

export const archiveFood = (foodId: string, channelId: string) => {
  const foodsCollectionRef = buildFoodsCollectionRef(channelId);
  const docRef = doc(foodsCollectionRef, foodId);

  return updateDoc(docRef, {
    archived: true,
    // Once archived, Foods no longer have an order.
    order: 0,
    archivedAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
  });
};

export const unarchiveFood = (foodId: string, channelId: string) => {
  const foodsCollectionRef = buildFoodsCollectionRef(channelId);
  const docRef = doc(foodsCollectionRef, foodId);

  return updateDoc(docRef, {
    archived: false,
    // Upon un-archiving, Foods should move to the top of the list..
    order: 0,
    archivedAt: null,
    updatedAt: serverTimestamp(),
  });
};

const buildFoodsCollectionRef = (channelId: string) =>
  collection(db, `channels/${channelId}/foods`);

/**
 * Transform a Firestore Food entity into the contract we use in the app.
 *
 * The Food title will attempt to be decrypted. If it fails, we still show the encrypted
 * Food title indicating to the end user they need to add a key.
 *
 * @param id Document ID from Firestore.
 * @param data Document data from Firestore.
 * @param decrypt Decrypting function.
 * @returns App representation of a Food.
 */
const transformToFood = async (
  id: string,
  data: DocumentData
): Promise<Food> => {
  return {
    id,
    /* When creating a Food, the createdAt date isn't known until the server operation
    has completed, and won't be available for optimistic updates. The current datetime
    is fine for our current purposes. */
    title: data.title,
    subtitle: data.subtitle,
    category: data.category,
    servingSize: data.servingSize,
    calories: data.calories,
    macros: data.macros,
    nutrients: data.nutrients,
    fiber: data.fiber,
    fodmaps: data.fodmaps,
    archived: data.archived ?? false,
    onMenu: data.onMenu ?? false,
    photoUrl: data.photoUrl,
    ingredients: data.ingredients,
    createdAt: data.createdAt ? data.createdAt.toDate() : new Date(),
    updatedAt: data.updatedAt ? data.updatedAt?.toDate() : undefined,
    recipeUpdatedAt: data.recipeUpdatedAt
      ? data.recipeUpdatedAt?.toDate()
      : undefined,
    archivedAt: data.updatedAt ? data.updatedAt?.toDate() : undefined,
  };
};

/**
 * Transform an app representation of a Food into a Firestore Food entity.
 *
 * WARNING: It's not safe to construct an object with all properties and set them
 * to undefined because that tells Firestore to null the field. Because of this,
 * it's only safe to transform required properties such as title, URL, etc.
 *
 * @param food
 * @returns
 */
const transformFromFood = async (food: Partial<Food>) => {
  let transformedFood = food;

  if (
    "recipeUpdatedAt" in transformedFood &&
    !transformedFood.recipeUpdatedAt
  ) {
    // Firestore doesn't allow undefined values.
    delete transformedFood.recipeUpdatedAt;
  }

  return transformedFood;
};
