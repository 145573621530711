import { useCallback, useMemo } from "react";

import { Food, FoodCategory, FoodCategoryEnum, User } from "../contracts";
import * as FoodsService from "../services/foods";
import { isFullInterface } from "../utils";
import { useAppContext } from "./useAppContext";

export type NewFood = Omit<Food, "id" | "createdAt">;
export type ExistingFood = Partial<Food> & { id: string };
export type SaveFoodType = ExistingFood | NewFood;

interface HookResult {
  /**
   * All Food from the app context store.
   */
  foods: Food[];

  /**
   * Food grouped by their category, sorted alphabetically within each group.
   *
   * @example { grains: [Food, Food], fruits: [Food] }
   */
  foodsGroupedByCategory: Partial<Record<FoodCategoryEnum, Food[]>>;

  /**
   * Food that should display in "today's menu" for the viewer.
   */
  foodsOnMenu: Food[];

  /**
   * Archived Food that should not display for the viewer.
   */
  archivedFoods: Food[];

  /**
   * Confirm a Food before permanent deletion.
   */
  confirmAndDelete: (foodId: string) => void;

  /**
   * Delete a Food. The deletion is persisted and permanent.
   */
  delete: (foodId: string) => void;

  /**
   * Get a Food from the app context store.
   */
  get: (foodId: string) => Food | undefined;

  /**
   * Persist a new Food, or existing Food.
   *
   * For new Food, do not set an `id` or `createdAt` property. All required properties
   * must be set except order.
   *
   * For existing Food, their `id` property must be set. Any number of other properties
   * may be set.
   */
  save: (food: SaveFoodType) => Promise<void>;

  /**
   * Move a live Food to the archived list.
   *
   * @param foodId ID of the Food to move.
   * @returns
   */
  archive: (foodId: string) => Promise<void>;

  /**
   * Move an archived Food back to the live list (the viewer can see).
   *
   * @param foodId ID of the Food to move.
   * @returns
   */
  unarchive: (foodId: string) => Promise<void>;
}

export const useFoods = () => {
  const { foods, user } = useAppContext();

  const getFood = useCallback(
    (foodId: string) => foods.find((food) => food.id === foodId),
    [foods]
  );

  const deleteFood = useCallback(
    (foodId: string) => {
      const { channelId } = assertUser(user);

      FoodsService.deleteFood(foodId, channelId);
    },
    [user]
  );

  const confirmAndDelete = useCallback(
    (foodId: string) => {
      const food = getFood(foodId);

      if (!food) {
        return;
      }

      const answer = window.confirm(
        `Are you sure you want to delete "${food.title}"?`
      );

      if (answer) {
        deleteFood(foodId);
      }
    },
    [getFood, deleteFood]
  );

  const save = useCallback(
    async (food: SaveFoodType) => {
      const { channelId } = assertUser(user);
      const exists = "id" in food && !!food.id;

      if (exists) {
        FoodsService.updateFood(food, channelId);
      } else if (isFullInterface<Omit<Food, "id">>(food)) {
        FoodsService.addFood(food, channelId);
      } else {
        throw new Error(
          "Unable to save invalid link. Does not match new or existing link structure."
        );
      }
    },
    [user]
  );

  const archive = useCallback(async (foodId: string) => {
    const { channelId } = assertUser(user);

    FoodsService.archiveFood(foodId, channelId);
  }, []);

  const unarchive = useCallback(async (foodId: string) => {
    const { channelId } = assertUser(user);

    FoodsService.unarchiveFood(foodId, channelId);
  }, []);

  const foodsGroupedByCategory = useMemo(() => {
    return Object.values(FoodCategory).reduce<
      Partial<Record<FoodCategoryEnum, Food[]>>
    >((acc, category) => {
      acc[category] = foods
        .filter((food) => food.category === category)
        .sort((a, b) => a.title.localeCompare(b.title));
      return acc;
    }, {});
  }, [foods]);

  const foodsOnMenu = useMemo(
    () => foods.filter((food) => food.onMenu),
    [foods]
  );

  const archivedFoods = useMemo(
    () => foods.filter((food) => food.archived),
    [foods]
  );

  const result = useMemo<HookResult>(
    () => ({
      foods,
      foodsGroupedByCategory,
      foodsOnMenu,
      archivedFoods,
      confirmAndDelete,
      delete: deleteFood,
      get: getFood,
      save,
      archive,
      unarchive,
    }),
    [
      foods,
      foodsGroupedByCategory,
      foodsOnMenu,
      archivedFoods,
      confirmAndDelete,
      deleteFood,
      getFood,
      save,
      archive,
      unarchive,
    ]
  );

  return result;
};

const assertUser = (user: User | undefined): User => {
  if (!user) {
    throw new Error("User required to be loaded, but it is not.");
  }

  return user;
};
