import * as Sentry from "@sentry/react";
import { onAuthStateChanged } from "firebase/auth";
import { ReactElement, createContext, useEffect, useState } from "react";
import { useErrorBoundary } from "react-error-boundary";

import { Food, User, UserType } from "../contracts";
import { auth } from "../firebase";
import { getUser, subscribeFoods, logout as firebaseLogout } from "../services";

export enum AppStatus {
  Initialising,
  Fetching,
  Ready,
}

interface AppState {
  /**
   * State representing if the user is creating or editing Food.
   */
  foodManagementState: { id: string | undefined } | undefined;

  /**
   * List of food shown in the app.
   */
  foods: Food[];

  /**
   * Is the app initialising (checking user, etc), ready to display, etc?
   */
  status: AppStatus;

  /**
   * Currently logged in user, if there is one.
   */
  user: User | undefined;

  /**
   * Show the user settings modal.
   */
  showSettings: boolean;
}

export interface AppContextValue extends AppState {
  /**
   * Is the app in "editor" mode for editing?
   */
  editorMode: boolean;

  /**
   * Log the currently logged in user out.
   */
  logout: () => void;

  /**
   * Update the app context state.
   *
   * @param newState Partial new state.
   */
  updateAppState: (newState: Partial<AppState>) => void;
}

export const AppContext = createContext<AppContextValue | undefined>(undefined);

const INITIAL_STATE: AppState = {
  foodManagementState: undefined,
  foods: [],
  status: AppStatus.Initialising,
  user: undefined,
  showSettings: false,
};

export const AppContextProvider = ({
  children,
}: {
  children: ReactElement;
}) => {
  const [appState, setAppState] = useState<AppState>(INITIAL_STATE);
  const { showBoundary } = useErrorBoundary();

  const editorMode = appState.user?.type === UserType.Editor;

  // Subscribe to auth state changes from Firebase.
  // TODO: Abstract into users service.
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        try {
          const user = await getUser(firebaseUser);
          updateAppState({ status: AppStatus.Fetching, user });
          Sentry.setUser({ id: user.id });
        } catch (error) {
          showBoundary(error);
        }
      } else {
        updateAppState({ status: AppStatus.Fetching, user: undefined });
        Sentry.setUser(null);
      }
    });

    /* No need for error handling, errors will only occur on sign-in:
    @see https://firebase.google.com/docs/reference/js/auth.md#onauthstatechanged */

    return () => unsubscribe();
  }, [showBoundary]);

  // Subscribe to data updates from Firestore.
  useEffect(() => {
    if (!appState.user?.channelId) {
      return;
    }

    try {
      const unsubscribe = subscribeFoods(
        (newFoods) => {
          updateAppState(() => {
            return {
              foods: newFoods,
              status: AppStatus.Ready,
            };
          });
        },
        appState.user.channelId,
        editorMode
      );
      return () => unsubscribe();
    } catch (error) {
      showBoundary(error);
    }
  }, [appState.user?.channelId, editorMode, showBoundary]);

  /**
   * Helper function to update the app-level state
   *
   * @param newState New partial state to merge.
   */
  const updateAppState = (
    newState: ((prevState: AppState) => Partial<AppState>) | Partial<AppState>
  ) => {
    setAppState((prevState) => ({
      ...prevState,
      ...(typeof newState === "function" ? newState(prevState) : newState),
    }));
  };

  const logout = () => {
    updateAppState(INITIAL_STATE);
    firebaseLogout();
  };

  const contextValue: AppContextValue = {
    ...appState,
    editorMode,
    logout,
    updateAppState,
  };

  // Provide the app context and state update function to the child components
  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};
