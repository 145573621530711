import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getStorage, connectStorageEmulator } from "firebase/storage";

import { config } from "./config";

const firebaseConfig = {
  apiKey: config.firebaseApiKey,
  appId: config.firebaseAppId,
  authDomain: config.firebaseAuthDomain,
  messagingSenderId: config.firebaseMessagingSenderId,
  projectId: config.firebaseProjectId,
  storageBucket: config.firebaseStorageBucket,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase auth.
export const auth = getAuth();
if (import.meta.env.MODE === "development") {
  connectAuthEmulator(auth, "http://127.0.0.1:9099");
}

// Initialize Firestore.
export const db = getFirestore(app);
if (import.meta.env.MODE === "development") {
  connectFirestoreEmulator(db, "127.0.0.1", 8080);
}

// Initialize Firebase storage.
export const storage = getStorage();
if (import.meta.env.MODE === "development") {
  connectStorageEmulator(storage, "127.0.0.1", 9199);
}
