export enum UserType {
  /**
   * The user can edit food, creating, editing, deleting, etc.
   */
  Editor = "editor",

  /**
   * The user can only view food.
   */
  Viewer = "viewer",
}

export interface User {
  /**
   * ID of the user's channel.
   */
  channelId: string;

  /**
   * Unique ID of the user.
   */
  id: string;

  /**
   * Name of the user.
   */
  name?: string;

  /**
   * Type of the user.
   */
  type: UserType;
}
