import {
  ref,
  uploadBytes,
  getDownloadURL,
  updateMetadata,
} from "firebase/storage";
import { nanoid } from "nanoid";
import slugify from "slugify";
import { ChangeEvent, useState } from "react";
import { storage } from "../firebase";
import { Button, Form, InputGroup } from "react-bootstrap";
import { Trash } from "@phosphor-icons/react";

interface FirebaseImageUploadProps {
  /**
   * URL of the current image, if it exists. If not, the string will be empty.
   */
  imageUrl: string;

  /**
   * Callback for when a new image is uploaded.
   *
   * @param url URL to the uploaded image, with a long-lasting Firebase Storage
   * access token.
   */
  onUpload: (url: string) => void;
}

export const FirebaseImageUpload = ({
  imageUrl,
  onUpload,
}: FirebaseImageUploadProps) => {
  const [file, setFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState(false);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return;
    }

    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (file) {
      const storageRef = ref(
        storage,
        `images/${nanoid()}-${slugify(file.name)}`
      );

      /* Intentionally not using a try-catch block here to allow errors to crash
      the Editor app and be picked up in Sentry. */

      setUploading(true);

      await uploadBytes(storageRef, file);
      const metadata = {
        cacheControl: "max-age=31536000", // Long-lived asset, 1 year in seconds
      };
      await updateMetadata(storageRef, metadata);

      const url = await getDownloadURL(storageRef);
      onUpload(url);
      setFile(null);
      setUploading(false);
    }
  };

  return (
    <>
      <div className="d-flex gap-2 align-items-center">
        <InputGroup>
          <Form.Control
            id="photo"
            type="file"
            accept="image/jpeg,image/webp"
            onChange={handleFileChange}
          />
          <Button type="button" variant="light" onClick={handleUpload}>
            {uploading ? "Uploading..." : "Upload"}
          </Button>
        </InputGroup>
        {imageUrl ? (
          <Button type="button" variant="danger" onClick={() => onUpload("")}>
            <Trash size={24} />
          </Button>
        ) : null}
      </div>

      <Form.Text id="photo" muted>
        Photo must be a WebP/JPG, less than 300kb in file size, and a 1.3:1
        aspect ratio i.e. 716x550.
      </Form.Text>
    </>
  );
};
