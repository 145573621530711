import { useState } from "react";
import { Container, Stack } from "react-bootstrap";
import { Route, Switch } from "wouter";
import { EditorAllPage } from "../pages/EditorAllPage";
import { EditorArchivePage } from "../pages/EditorArchivePage";
import { EditorMenuPage } from "../pages/EditorMenuPage";
import { EditorNavbar } from "./EditorNavbar";
import { FoodModal } from "./FoodModal/FoodModal";
import { EditorListSearch } from "./EditorListSearch";
import { EditorTabs } from "./EditorTabs";
import classes from "./EditorApp.module.css";

export const EditorApp = () => {
  const [searchQuery, setSearchQuery] = useState("");

  return (
    <div>
      <EditorNavbar />
      <Stack gap={3}>
        <EditorTabs />

        <Container>
          <EditorListSearch
            query={searchQuery}
            onSearch={(query) => setSearchQuery(query)}
          />
        </Container>
      </Stack>

      <Container className={classes["page-wrapper"]}>
        <Switch>
          <Route path="/archive">
            <EditorArchivePage query={searchQuery} />
          </Route>
          <Route path="/all">
            <EditorAllPage query={searchQuery} />
          </Route>
          <Route>
            <EditorMenuPage query={searchQuery} />
          </Route>
        </Switch>

        <FoodModal />
      </Container>
    </div>
  );
};
