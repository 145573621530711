import { Plus, Trash } from "@phosphor-icons/react";
import { useEffect, useRef } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";

interface IngredientsFieldsProps {
  /**
   * Controlled list of ingredient labels.
   */
  ingredients: string[];

  /**
   * Callback for emitting a new list of ingredients.
   *
   * @param ingredients New list of ingredients.
   */
  onChange(ingredients: string[]): void;
}

/**
 * Form fields for managing multiple ingredients.
 */
export const IngredientsFields = ({
  ingredients,
  onChange,
}: IngredientsFieldsProps) => {
  const lastInputRef = useRef<HTMLInputElement>(null);

  // Focus newly added fields, after the new field is rendered.
  useEffect(() => {
    if (lastInputRef.current) {
      lastInputRef.current.focus();
    }
  }, [ingredients.length]);

  function addIngredient() {
    const newIngredients = [...ingredients, ""];
    onChange(newIngredients);
  }

  function deleteIngredient(index: number) {
    const newIngredients = ingredients.filter((_, i) => i !== index);
    onChange(newIngredients);
  }

  function handleFieldChange(index: number, value: string) {
    const newIngredients = ingredients.map((ingredient, i) =>
      i === index ? value : ingredient
    );
    onChange(newIngredients);
  }

  const ingredientList = ingredients.map((ingredient, index) => {
    return (
      <Col md={4} key={index}>
        <InputGroup>
          <Form.Control
            ref={index === ingredients.length - 1 ? lastInputRef : null}
            type="text"
            required
            value={ingredient}
            onChange={(event) => handleFieldChange(index, event.target.value)}
          />
          <Button
            type="button"
            variant="danger"
            // Allow tabbing between fields without being interrupted by the buttons.
            tabIndex={-1}
            onClick={() => deleteIngredient(index)}
          >
            <Trash size={24} />
          </Button>
        </InputGroup>
      </Col>
    );
  });

  return (
    <>
      <Row className="row-gap-3 mb-3">{ingredientList}</Row>

      <div className="d-flex justify-content-end">
        <Button
          type="button"
          variant="light"
          className="d-flex align-items-center gap-1"
          onClick={() => addIngredient()}
        >
          <Plus />
          Add ingredient
        </Button>
      </div>
    </>
  );
};
