import { useMemo } from "react";
import { FoodsList } from "../components/FoodsList/FoodsList";
import { useFoods, useSearchFoods } from "../hooks";

interface EditorMenuPageProps {
  /**
   * An optional search filter for the foods.
   */
  query?: string;
}

export const EditorMenuPage = ({ query }: EditorMenuPageProps) => {
  const { foodsOnMenu } = useFoods();
  const { search } = useSearchFoods(foodsOnMenu);

  const foodsList = useMemo(() => {
    let result =
      // Menu list foods are sorted alphabetically.
      foodsOnMenu.sort((a, b) => a.title.localeCompare(b.title));

    if (query) {
      const searchedFoods = search(query);
      result = result.filter((food) =>
        searchedFoods.some((searchedFood) => searchedFood.id === food.id)
      );
    }

    return result;
  }, [query, foodsOnMenu]);

  return (
    <div className="px-3 py-4">
      <FoodsList foods={foodsList} />
    </div>
  );
};
