import { Stack } from "react-bootstrap";
import { Food as FoodType } from "../../contracts";
import { Food } from "./Food";

interface FoodsListProps {
  /**
   * Foods to display.
   */
  foods: FoodType[];
}

/**
 * Display a list of Foods.
 *
 * All filtering, sorting, etc is the responsibility of the parent component.
 */
export const FoodsList = ({ foods }: FoodsListProps) => {
  return (
    <Stack gap={5}>
      {foods.map(({ id }, index) => (
        <Food
          id={id}
          key={id}
          /* Only the first card should trigger settings to avoid the links at
          the bottom of the screen accidentally triggering the settings. This can
          happen when swiping a link to minimise the app, the link thinks it
          is still being pressed while the app is minimised and triggers settings. */
          sneakySettingsLink={index === 0}
        />
      ))}
    </Stack>
  );
};
