import { Plus } from "@phosphor-icons/react";
import { Button } from "react-bootstrap";
import { useAppContext } from "../hooks";

export const AddFoodButton = () => {
  const { updateAppState } = useAppContext();

  const handleClickAdd = () => {
    updateAppState({
      foodManagementState: { id: undefined },
    });
  };

  return (
    <Button
      className="d-flex align-items-csenter gap-1"
      onClick={handleClickAdd}
      type="button"
      variant="light"
    >
      <Plus size={24} />
    </Button>
  );
};
