import { useMemo } from "react";
import { FoodsCategoryList } from "../components/FoodsList/FoodsCategoryList";
import { FoodListItem } from "../components/FoodsList/types";
import { FoodCategory, FoodCategoryEnum } from "../contracts";
import { useFoods, useSearchFoods } from "../hooks";

interface EditorAllPageProps {
  /**
   * An optional search filter for the foods.
   */
  query?: string;
}

export const EditorAllPage = ({ query }: EditorAllPageProps) => {
  const { foods: allFoods, foodsGroupedByCategory } = useFoods();
  const { search } = useSearchFoods(allFoods);

  const foodsList = useMemo(() => {
    const foods = Object.entries(foodsGroupedByCategory);

    const newFoodsList = foods.reduce<FoodListItem[]>(
      (acc, [category, foods]) => {
        // Lookup the category enum key to get the label.
        const categoryLabel = Object.keys(FoodCategory).find(
          (key) => FoodCategory[key as keyof typeof FoodCategory] === category
        ) as FoodCategoryEnum;

        if (query) {
          const searchedFoods = search(query);
          foods = foods.filter((food) =>
            searchedFoods.some((searchedFood) => searchedFood.id === food.id)
          );
        }

        foods = foods.filter((food) => !food.archived);

        if (foods.length > 0) {
          acc.push({ category, categoryLabel, foods });
        }

        return acc;
      },
      []
    );

    return newFoodsList;
  }, [query, foodsGroupedByCategory]);

  return <FoodsCategoryList foods={foodsList} />;
};
