import { Button, Nav } from "react-bootstrap";
import { FoodCategory } from "../contracts";
import classes from "./FoodCategoryMenu.module.css";

export function FoodCategoryMenu() {
  return (
    <Nav variant="pills" className={classes["food-category-menu"]}>
      {Object.entries(FoodCategory).map(([label, id]) => (
        <Button key={id} href={`#category-${id}`} variant="outline-primary">
          {label}
        </Button>
      ))}
    </Nav>
  );
}
