import { useContext } from "react";

import { FoodModalContext } from "../contexts";

export const useFoodModalContext = () => {
  const context = useContext(FoodModalContext);

  if (context === undefined) {
    throw new Error(
      "useFoodModalContext must be used within an FoodModalContext.Provider"
    );
  }

  return context;
};
