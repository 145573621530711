import Fuse from "fuse.js";
import { useMemo, useRef } from "react";
import { Food } from "../contracts";

export function useSearchFoods(foods: Food[]) {
  const fuse = useRef<Fuse<Food> | null>(null);

  useMemo(() => {
    /* Fuzzy search to filter the Foods. With basic testing, executing this on every
    render is fine. Changing search query is debounced. */
    fuse.current = new Fuse(foods, {
      keys: ["title", "from", "flag"],
      // We don't care how far into the title the match is.
      ignoreLocation: true,
      // We want strict matches, with only a little fuzziness allowed.
      threshold: 0.2,
    });
  }, [foods]);

  function search(query: string) {
    if (!fuse.current) {
      throw new Error("Fuse instance not initialized.");
    }

    return fuse.current.search(query).map(({ item }) => item);
  }

  return { search };
}
