import { Stack } from "react-bootstrap";
import { Heading } from "../Heading";
import { Food } from "./Food";
import { FoodListItem } from "./types";
import classes from "./FoodsCategoryList.module.scss";

interface FoodsCategoryListProps {
  foods: FoodListItem[];
}

/**
 * Display a list of Foods grouped by category.
 */
export const FoodsCategoryList = ({
  foods: foodsList,
}: FoodsCategoryListProps) => {
  return (
    <Stack
      // Stack separating categories.
      gap={5}
    >
      {foodsList.map(({ category, categoryLabel, foods }) => (
        <div
          key={category}
          id={`category-${category}`}
          className={classes.category}
        >
          <Heading level={1} className="mb-3">
            {categoryLabel}
          </Heading>
          <Stack
            // Stack separating foods within a category.
            gap={5}
          >
            {foods.map(({ id }) => (
              <Food id={id} key={id} />
            ))}
          </Stack>
        </div>
      ))}
    </Stack>
  );
};
