import { format, parse } from "date-fns";
import { FormEventHandler, useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useErrorBoundary } from "react-error-boundary";
import { useLocation } from "wouter";
import { Fodmap, FoodCategory, FoodCategoryEnum } from "../../contracts";
import {
  SaveFoodType,
  useAppContext,
  useFoodModalContext,
  useFoods,
} from "../../hooks";
import { FirebaseImageUpload } from "../FirebaseImageUpload";
import { FodmapsFields } from "./FodmapsFields";
import { IngredientsFields } from "./IngredientsFields";

interface FormState {
  title: string;
  subtitle: string;
  category: FoodCategoryEnum | "";
  servingSize: string;
  calories: string;
  carbs: string;
  protein: string;
  fat: string;
  calcium: string;
  magnesium: string;
  totalFiber: string;
  insolubleFiber: string;
  solubleFiber: string;
  recipeUpdatedAt: string;
  fodmaps: Fodmap[];
  ingredients: string[];

  /**
   * Not displayed in the form, here to ensure it isn't null when creating a new
   * Food. If it was null the Food won't be returned in Firebase queries.
   */
  archived: boolean;
  onMenu: boolean;
  photoUrl: string;
}

/**
 * Form for creating new Foods and editing existing Foods.
 */
export const FoodForm = () => {
  const [_location, setLocation] = useLocation();
  const { foodManagementState, updateAppState } = useAppContext();
  const { get: getFood, save: saveFood } = useFoods();
  const { showBoundary } = useErrorBoundary();
  const { setDirty, setSubmitting } = useFoodModalContext();
  const [formState, setFormState] = useState<FormState>({
    title: "",
    subtitle: "",
    category: "",
    servingSize: "",
    calories: "",
    carbs: "",
    protein: "",
    fat: "",
    calcium: "",
    magnesium: "",
    totalFiber: "",
    insolubleFiber: "",
    solubleFiber: "",
    fodmaps: [],
    recipeUpdatedAt: "",
    archived: false,
    onMenu: false,
    photoUrl: "",
    ingredients: [],
  });
  const titleInputRef = useRef<HTMLInputElement>(null);

  // When editing a Food, initially populate the form with that Food's data.
  useEffect(() => {
    if (!foodManagementState?.id) {
      return;
    }

    const food = getFood(foodManagementState?.id);

    if (!food) {
      return;
    }

    // Convert Food model to form state.
    setFormState({
      title: food.title,
      subtitle: food.subtitle ?? "",
      category: food.category,
      servingSize: food.servingSize,
      calories: `${food.calories}`,
      carbs: `${food.macros.carbohydrates}`,
      protein: `${food.macros.protein}`,
      fat: `${food.macros.fat}`,
      calcium: `${food.nutrients.calcium}`,
      magnesium: `${food.nutrients.magnesium}`,
      totalFiber: `${food.fiber.total}`,
      solubleFiber: `${food.fiber.soluble}`,
      insolubleFiber: `${food.fiber.insoluble}`,
      fodmaps: food.fodmaps,
      recipeUpdatedAt: food.recipeUpdatedAt
        ? formatDate(food.recipeUpdatedAt)
        : "",
      archived: food.archived,
      onMenu: food.onMenu,
      photoUrl: food.photoUrl ?? "",
      ingredients: food.ingredients ?? [],
    });
  }, [foodManagementState?.id, getFood]);

  // Focus the first field when showing the form.
  useEffect(() => {
    if (!titleInputRef.current) {
      throw Error("titleInputRef is not assigned.");
    }

    if (!foodManagementState?.id) {
      titleInputRef.current.focus();
    }
  }, [foodManagementState?.id]);

  const save = async () => {
    try {
      setSubmitting(true);

      if (!formState.category) {
        throw new Error("Missing required Food category.");
      }

      // Convert form state to Food model.
      const food: SaveFoodType = {
        ...(foodManagementState?.id ? { id: foodManagementState.id } : {}),
        title: formState.title,
        subtitle: formState.subtitle,
        category: formState.category || undefined,
        servingSize: formState.servingSize,
        calories: parseFloat(formState.calories),
        macros: {
          carbohydrates: parseFloat(formState.carbs),
          protein: parseFloat(formState.protein),
          fat: parseFloat(formState.fat),
        },
        nutrients: {
          calcium: parseFloat(formState.calcium),
          magnesium: parseFloat(formState.magnesium),
        },
        fiber: {
          total: parseFloat(formState.totalFiber),
          soluble: parseFloat(formState.solubleFiber),
          insoluble: parseFloat(formState.insolubleFiber),
        },
        fodmaps: formState.fodmaps,
        ingredients: formState.ingredients,
        archived: formState.archived,
        onMenu: formState.onMenu,
        photoUrl: formState.photoUrl,
        recipeUpdatedAt: formState.recipeUpdatedAt
          ? parseDate(formState.recipeUpdatedAt)
          : undefined,
      };

      await saveFood(food);
      close();

      // If adding a new Food, reset to the live view to see it.
      if (!foodManagementState?.id) {
        setLocation("/");
      }
    } catch (error) {
      showBoundary(error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleFieldChange = (key: keyof FormState, value: unknown) => {
    const newFormState = {
      ...formState,
      [key]: value,
    };

    setFormState(newFormState);
    setDirty(true);
  };

  const handleSubmit: FormEventHandler = (event) => {
    event.preventDefault();

    save();
  };

  function close() {
    updateAppState({ foodManagementState: undefined });
    setDirty(false);
  }

  return (
    <Form id="food-form" onSubmit={handleSubmit}>
      <Row className="mb-3">
        <Col>
          <Form.Label htmlFor="title">Title*</Form.Label>
          <Form.Control
            id="title"
            onChange={(event) => handleFieldChange("title", event.target.value)}
            ref={titleInputRef}
            required
            type="text"
            value={formState.title}
          />
        </Col>
        <Col>
          <Form.Label htmlFor="subtitle">Subtitle</Form.Label>
          <Form.Control
            id="subtitle"
            onChange={(event) =>
              handleFieldChange("subtitle", event.target.value)
            }
            type="text"
            value={formState.subtitle}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <Form.Label htmlFor="category">Category*</Form.Label>
          <Form.Select
            id="category"
            value={formState.category}
            required
            onChange={(event) =>
              handleFieldChange("category", event.target.value)
            }
          >
            <option value="">Select a Food category</option>
            {Object.entries(FoodCategory).map(([label, value]) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Form.Select>
        </Col>
        <Col>
          <Form.Label htmlFor="serving-size">Serving Size*</Form.Label>
          <Form.Control
            id="serving-size"
            onChange={(event) =>
              handleFieldChange("servingSize", event.target.value)
            }
            required
            type="text"
            value={formState.servingSize}
          />
        </Col>
        <Col>
          <Form.Label htmlFor="recipeUpdatedAt">Recipe Changed</Form.Label>
          <Form.Control
            id="recipeUpdatedAt"
            onChange={(event) =>
              handleFieldChange("recipeUpdatedAt", event.target.value)
            }
            type="date"
            value={formState.recipeUpdatedAt}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <Form.Label htmlFor="onMenu">On Today's Menu</Form.Label>
          <Form.Check
            type="switch"
            id="onMenu"
            checked={formState.onMenu}
            onChange={(event) =>
              handleFieldChange("onMenu", event.target.checked)
            }
          />
        </Col>
      </Row>

      <h4>Nutrients</h4>

      <Row className="mb-3">
        <Col>
          <Form.Label htmlFor="calories">Calories*</Form.Label>
          <Form.Control
            id="calories"
            onChange={(event) =>
              handleFieldChange("calories", event.target.value)
            }
            required
            type="number"
            value={formState.calories}
          />
        </Col>
        <Col>
          <Form.Label htmlFor="carbs">Carbs (g)*</Form.Label>
          <Form.Control
            id="carbs"
            onChange={(event) => handleFieldChange("carbs", event.target.value)}
            required
            type="number"
            value={formState.carbs}
          />
        </Col>
        <Col>
          <Form.Label htmlFor="fat">Fat (g)*</Form.Label>
          <Form.Control
            id="fat"
            onChange={(event) => handleFieldChange("fat", event.target.value)}
            required
            type="number"
            value={formState.fat}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <Form.Label htmlFor="protein">Protein (g)*</Form.Label>
          <Form.Control
            id="protein"
            onChange={(event) =>
              handleFieldChange("protein", event.target.value)
            }
            required
            type="number"
            value={formState.protein}
          />
        </Col>
        <Col>
          <Form.Label htmlFor="calcium">Calcium (mg)*</Form.Label>
          <Form.Control
            id="calcium"
            onChange={(event) =>
              handleFieldChange("calcium", event.target.value)
            }
            required
            type="number"
            value={formState.calcium}
          />
        </Col>
        <Col>
          <Form.Label htmlFor="magnesium">Magnesium (mg)*</Form.Label>
          <Form.Control
            id="magnesium"
            onChange={(event) =>
              handleFieldChange("magnesium", event.target.value)
            }
            required
            type="number"
            value={formState.magnesium}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <Form.Label htmlFor="total-fiber">Total Fiber*</Form.Label>
          <Form.Control
            id="total-fiber"
            onChange={(event) =>
              handleFieldChange("totalFiber", event.target.value)
            }
            required
            type="number"
            value={formState.totalFiber}
          />
        </Col>
        <Col>
          <Form.Label htmlFor="insoluble-fiber">Insoluble Fiber*</Form.Label>
          <Form.Control
            id="insoluble-fiber"
            onChange={(event) =>
              handleFieldChange("insolubleFiber", event.target.value)
            }
            required
            type="number"
            value={formState.insolubleFiber}
          />
        </Col>
        <Col>
          <Form.Label htmlFor="soluble-fiber">Soluble Fiber*</Form.Label>
          <Form.Control
            id="soluble-fiber"
            onChange={(event) =>
              handleFieldChange("solubleFiber", event.target.value)
            }
            required
            type="number"
            value={formState.solubleFiber}
          />
        </Col>
      </Row>

      <h4>FODMAPs</h4>

      <FodmapsFields
        fodmaps={formState.fodmaps}
        onChange={(newFodmaps) => handleFieldChange("fodmaps", newFodmaps)}
      />

      <h4>Ingredients</h4>

      <IngredientsFields
        ingredients={formState.ingredients}
        onChange={(newIngredients) =>
          handleFieldChange("ingredients", newIngredients)
        }
      />

      <h4>Photo</h4>

      <Form.Group className="mb-3">
        <Form.Label htmlFor="photo">Upload Image</Form.Label>
        <FirebaseImageUpload
          imageUrl={formState.photoUrl}
          onUpload={(imageUrl) => handleFieldChange("photoUrl", imageUrl)}
        />
      </Form.Group>
    </Form>
  );
};

function formatDate(date: Date) {
  return format(date, "yyyy-MM-dd");
}

function parseDate(date: string) {
  return parse(date, "yyyy-MM-dd", new Date());
}
