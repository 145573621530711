import { MouseEventHandler } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { useErrorBoundary } from "react-error-boundary";
import { SaveFoodType, useAppContext, useFoods } from "../../hooks";
import { Archive, CalendarDot, Pencil, Trash } from "@phosphor-icons/react";

interface FoodActionsProps {
  /**
   * ID for the Food to trigger actions against.
   */
  foodId: string;

  /**
   * Is the Food archived?
   */
  archived?: boolean;

  /**
   * Is the Food on today's menu?
   */
  onMenu?: boolean;
}

/**
 * Editor-only actions for updating existing Foods.
 */
export const FoodActions = ({
  foodId: id,
  archived,
  onMenu,
}: FoodActionsProps) => {
  const { updateAppState } = useAppContext();
  const {
    confirmAndDelete: confirmAndDeleteFood,
    archive: archiveFood,
    unarchive: unarchiveFood,
    save: saveFood,
  } = useFoods();
  const { showBoundary } = useErrorBoundary();

  const handleClickEdit: MouseEventHandler<HTMLButtonElement> = () => {
    updateAppState({ foodManagementState: { id } });
  };

  const handleClickDelete: MouseEventHandler<HTMLButtonElement> = () => {
    try {
      confirmAndDeleteFood(id);
    } catch (error) {
      showBoundary(error);
    }
  };

  const handleClickArchive: MouseEventHandler<HTMLButtonElement> = () => {
    try {
      if (archived) {
        unarchiveFood(id);
      } else {
        archiveFood(id);
      }
    } catch (error) {
      showBoundary(error);
    }
  };

  const handleClickOnMenu: MouseEventHandler<HTMLButtonElement> = () => {
    try {
      const food: SaveFoodType = {
        id,
        onMenu: !onMenu,
      };

      saveFood(food);
    } catch (error) {
      showBoundary(error);
    }
  };

  return (
    <ButtonGroup size="sm" aria-label="Food actions">
      <Button
        onClick={handleClickEdit}
        type="button"
        variant="outline-primary"
        aria-label="Edit"
        title="Edit"
      >
        <Pencil size={24} />
      </Button>
      {!archived ? (
        <Button
          onClick={handleClickOnMenu}
          type="button"
          variant="outline-primary"
          aria-label="Today's Menu"
          title="Today's Menu"
          active={onMenu}
        >
          <CalendarDot size={24} />
        </Button>
      ) : null}
      <Button
        onClick={handleClickArchive}
        type="button"
        variant="outline-primary"
        aria-label={archived ? "Un-archive" : "Archive"}
        title={archived ? "Un-archive" : "Archive"}
        active={archived}
      >
        <Archive size={24} />
      </Button>
      <Button
        onClick={handleClickDelete}
        type="button"
        variant="outline-danger"
        aria-label="Delete"
        title="Delete"
      >
        <Trash size={24} />
      </Button>
    </ButtonGroup>
  );
};
