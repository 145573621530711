import clsx from "clsx";
import { format } from "date-fns";
import { Image } from "react-bootstrap";
import Stack from "react-bootstrap/Stack";
import { useAppContext, useFoods, useSneakySettings } from "../../hooks";
import { FoodActions } from "./FoodActions";
import { MaybeNewRecipeBadge } from "./MaybeNewRecipeBadge";
import { FodmapsList } from "./FodmapsList";
import classes from "./Food.module.scss";
import { Badge } from "../Badge";
import { Heading } from "../Heading";

export interface FoodProps {
  className?: string;

  /**
   * The ID of the Food to display.
   */
  id: string;

  /**
   * Tapping and holding this link should show the settings screen.
   */
  sneakySettingsLink?: boolean;
}

/**
 * Display a Food in different ways depending on if in editor mode or not.
 *
 * In editor mode Food can be edited and deleted.
 */
export const Food = ({ className, id, sneakySettingsLink }: FoodProps) => {
  const { editorMode } = useAppContext();
  const { get: getFood } = useFoods();
  const bindSneakySettings = useSneakySettings();

  const food = getFood(id);

  if (!food) {
    return null;
  }

  const {
    title,
    subtitle,
    servingSize,
    calories,
    macros,
    fiber,
    fodmaps,
    recipeUpdatedAt,
    archived,
    photoUrl,
    archivedAt,
    onMenu,
    ingredients,
  } = food;

  const previewFodmaps = fodmaps.filter((fodmap) => fodmap.showInMainView);

  function formatNutritionValue(value: number, suffix = "") {
    if (value === 0) {
      return "-";
    } else if (value > 0 && value < 0.1) {
      return "trace";
    }

    return `${value}${suffix}`;
  }

  return (
    <div
      // For easier debugging.
      data-food-id={id}
      className={clsx(classes.food, className)}
    >
      {photoUrl ? (
        <div
          className={classes["image-wrapper"]}
          {...(sneakySettingsLink ? bindSneakySettings() : undefined)}
        >
          <Image
            src={photoUrl}
            alt={`Photo of ${title}`}
            fluid
            className={classes["image-wrapper__image"]}
          />
          {recipeUpdatedAt ? (
            <div className={classes["image-wrapper__badge"]}>
              <MaybeNewRecipeBadge recipeUpdatedAt={recipeUpdatedAt} />
            </div>
          ) : null}
        </div>
      ) : null}

      <Stack gap={3} className={classes.details}>
        <div className="d-flex align-items-center justify-content-between gap-2">
          <Heading level={2} className={clsx(classes.title, "mb-0")}>
            {title} {subtitle ? `(${subtitle})` : null}
          </Heading>

          <Badge>{servingSize}</Badge>
        </div>

        {editorMode ? (
          <FoodActions foodId={id} archived={archived} onMenu={onMenu} />
        ) : null}

        <div className={classes["nutrients-panel"]}>
          <div className={classes["nutrients-panel__nutrient"]}>
            <Heading level={3} className="mb-0">
              {formatNutritionValue(calories)}
            </Heading>
            <span className={classes["nutrients-panel__label"]}>calories</span>
          </div>
          <div className={classes["nutrients-panel__nutrient"]}>
            <Heading level={3} className="mb-0">
              {formatNutritionValue(macros.carbohydrates)}g
            </Heading>
            <span className={classes["nutrients-panel__label"]}>carbs</span>
          </div>
          <div className={classes["nutrients-panel__nutrient"]}>
            <Heading level={3} className="mb-0">
              {formatNutritionValue(macros.protein, "g")}
            </Heading>
            <span className={classes["nutrients-panel__label"]}>protein</span>
          </div>
          <div className={classes["nutrients-panel__nutrient"]}>
            <Heading level={3} className="mb-0">
              {formatNutritionValue(macros.fat, "g")}
            </Heading>
            <span className={classes["nutrients-panel__label"]}>fat</span>
          </div>
        </div>

        <Heading level={3} variant="secondary" className="mb-0">
          Fiber
        </Heading>

        <div className={classes["nutrients-panel"]}>
          <div className={classes["nutrients-panel__nutrient"]}>
            <Heading level={3} className="mb-0">
              {formatNutritionValue(fiber.total, "g")}
            </Heading>
            <span className={classes["nutrients-panel__label"]}>total</span>
          </div>
          <div className={classes["nutrients-panel__nutrient"]}>
            <Heading level={3} className="mb-0">
              {formatNutritionValue(fiber.soluble, "g")}
            </Heading>
            <span className={classes["nutrients-panel__label"]}>soluble</span>
          </div>
          <div className={classes["nutrients-panel__nutrient"]}>
            <Heading level={3} className="mb-0">
              {formatNutritionValue(fiber.insoluble, "g")}
            </Heading>
            <span className={classes["nutrients-panel__label"]}>insoluble</span>
          </div>
        </div>

        {ingredients?.length ? (
          <div>
            <Heading level={3} variant="secondary">
              Ingredients
            </Heading>
            <div>{ingredients.join(", ")}</div>
          </div>
        ) : null}

        {previewFodmaps.length ? (
          <div>
            <Heading level={3} variant="secondary">
              FODMAPs
            </Heading>
            <FodmapsList fodmaps={previewFodmaps} />
          </div>
        ) : null}

        {archived && archivedAt ? (
          <p className="mb-0">
            Archived on {format(archivedAt, "MMMM d, yyyy")}
          </p>
        ) : null}
      </Stack>
    </div>
  );
};
