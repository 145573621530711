import { Route, Switch, useLocation } from "wouter";
import { ViewerMenuPage } from "../pages/ViewerMenuPage";
import { ViewerAllPage } from "../pages/ViewerAllPage";
import { ViewerNav } from "./ViewerNav";
import classes from "./ViewerApp.module.scss";
import { useEffect } from "react";

export const ViewerApp = () => {
  const [location] = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, [location]);

  return (
    <div className={classes["viewer-app"]}>
      <Switch>
        <Route path="/all">
          <ViewerAllPage />
        </Route>
        <Route>
          <ViewerMenuPage />
        </Route>
      </Switch>

      <ViewerNav />
    </div>
  );
};
