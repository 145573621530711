import { Button } from "react-bootstrap";
import { useDebounce } from "react-use";
import classes from "./BackToTopButton.module.scss";
import { useEffect, useState } from "react";
import clsx from "clsx";

export function BackToTopButton() {
  const [isVisible, setIsVisible] = useState(false);
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useDebounce(
    () => {
      if (scrollY > 200) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    },
    20,
    [scrollY]
  );

  return (
    <Button
      href="#top"
      className={clsx(
        classes["back-to-top-button"],
        isVisible && classes.visible
      )}
    >
      Back to Top
    </Button>
  );
}
