import { useMemo } from "react";
import { FoodsList } from "../components/FoodsList/FoodsList";
import { useFoods } from "../hooks";

export const ViewerMenuPage = () => {
  const { foodsOnMenu } = useFoods();

  const foodsList = useMemo(
    () =>
      // Menu list foods are sorted alphabetically.
      foodsOnMenu.sort((a, b) => a.title.localeCompare(b.title)),
    [foodsOnMenu]
  );

  return (
    <div className="px-3 py-4">
      <FoodsList foods={foodsList} />
    </div>
  );
};
