import { ArchivedLinkList } from "../components/ArchivedLinkList";

interface EditorArchivePageProps {
  /**
   * An optional search filter for the foods.
   */
  query?: string;
}

export const EditorArchivePage = ({ query }: EditorArchivePageProps) => {
  return <ArchivedLinkList query={query ?? ""} />;
};
