import { Button, Container, Navbar } from "react-bootstrap";

import { AddFoodButton } from "./AddFoodButton";
import { useAppContext } from "../hooks";
import { Gear } from "@phosphor-icons/react";

export const EditorNavbar = () => {
  const { updateAppState } = useAppContext();

  const handleClickSettings = () => {
    updateAppState({ showSettings: true });
  };

  return (
    <Navbar bg="primary" sticky="top" variant="dark">
      <Container fluid>
        <Navbar.Brand>Food Friend</Navbar.Brand>

        <div className="d-flex gap-2">
          <Button onClick={handleClickSettings} variant="secondary">
            <Gear size={24} />
          </Button>
          <AddFoodButton />
        </div>
      </Container>
    </Navbar>
  );
};
