import { CheckCircle, WarningCircle } from "@phosphor-icons/react";
import clsx from "clsx";
import classes from "./Alert.module.css";

interface AlertProps {
  children: React.ReactNode;

  /**
   * Alert color variant.
   */
  variant: "success" | "warning" | "danger";
}

export function Alert({ children, variant }: AlertProps) {
  const IconMap = {
    success: CheckCircle,
    warning: WarningCircle,
    danger: WarningCircle,
  };

  const Icon = IconMap[variant];

  return (
    <div
      className={clsx(
        classes.alert,
        variant === "success" && classes["alert--success"],
        variant === "warning" && classes["alert--warning"],
        variant === "danger" && classes["alert--danger"]
      )}
    >
      <Icon size={32} />
      {children}
    </div>
  );
}
