import clsx from "clsx";
import classes from "./Heading.module.css";

interface HeadingProps {
  children: React.ReactNode;

  /**
   * Additional class name.
   */
  className?: string;

  /**
   * Heading level.
   *
   * @example 1
   */
  level: 1 | 2 | 3 | 4 | 5 | 6;

  /**
   * Heading text color variant.
   *
   * @default "primary"
   */
  variant?: "primary" | "secondary";
}

export function Heading({
  children,
  className,
  level,
  variant = "primary",
}: HeadingProps) {
  const HeadingTag = `h${level}` as "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

  return (
    <HeadingTag
      className={clsx(
        variant === "primary" && classes["heading--primary"],
        variant === "secondary" && classes["heading--secondary"],
        className
      )}
    >
      {children}
    </HeadingTag>
  );
}
