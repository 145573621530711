import { EditorApp } from "./components/EditorApp";
import { LoginForm } from "./components/LoginForm";
import { OfflineStatus } from "./components/OfflineStatus";
import { SettingsModal } from "./components/SettingsModal";
import { ViewerApp } from "./components/ViewerApp";
import { AppStatus } from "./contexts";
import { useAppContext } from "./hooks";
import classes from "./App.module.css";

const App = () => {
  const { editorMode, status, user } = useAppContext();

  if (status === AppStatus.Fetching && !user) {
    return (
      <div className={classes["login-wrapper"]}>
        <LoginForm />
      </div>
    );
  }

  if (status !== AppStatus.Ready) {
    return (
      <div className={classes["loading-wrapper"]}>
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <>
      <OfflineStatus />
      {editorMode ? <EditorApp /> : <ViewerApp />}
      <SettingsModal />
    </>
  );
};

export default App;
