import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";

import App from "./App.tsx";
import { UnexpectedError } from "./components/UnexpectedError.tsx";
import { AppContextProvider } from "./contexts/AppContext.tsx";
import "./firebase.ts";
import "./scss/styles.scss";
import { captureError } from "./utils";

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: "https://2a221d8c8d3132006ff4121b8ce58c60@o71499.ingest.us.sentry.io/4507718739230720",
    environment: import.meta.env.MODE,
  });
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <ErrorBoundary
      FallbackComponent={UnexpectedError}
      onError={captureError}
      onReset={() => window.location.reload()}
    >
      <AppContextProvider>
        <App />
      </AppContextProvider>
    </ErrorBoundary>
  </React.StrictMode>
);
