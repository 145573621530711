import { useMemo } from "react";
import { BackToTopButton } from "../components/BackToTopButton";
import { FoodCategoryMenu } from "../components/FoodCategoryMenu";
import { FoodsCategoryList } from "../components/FoodsList/FoodsCategoryList";
import { FoodListItem } from "../components/FoodsList/types";
import { FoodCategory, FoodCategoryEnum } from "../contracts";
import { useFoods } from "../hooks";

export const ViewerAllPage = () => {
  const { foodsGroupedByCategory } = useFoods();

  // Group the foods under their category.
  const foodsList = useMemo(() => {
    const foods = Object.entries(foodsGroupedByCategory);

    return foods.reduce<FoodListItem[]>((acc, [category, foods]) => {
      // Lookup the category enum key to get the label.
      const categoryLabel = Object.keys(FoodCategory).find(
        (key) => FoodCategory[key as keyof typeof FoodCategory] === category
      ) as FoodCategoryEnum;

      acc.push({ category, categoryLabel, foods });
      return acc;
    }, []);
  }, [foodsGroupedByCategory]);

  return (
    <div id="top" className="px-3 py-4">
      <div className="mb-4">
        <FoodCategoryMenu />
      </div>

      <FoodsCategoryList foods={foodsList} />

      <BackToTopButton />
    </div>
  );
};
